// step 1
.location {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  height: calc(100vh - 75px);
  .locationInner {
    position: relative;
    input {
      min-width: 600px;
      max-width: 600px;
      @media only screen and (max-width: 760px) {
        min-width: 400px;
      }
      @media only screen and (max-width: 425px) {
        min-width: 0;
      }
    }
    .predictions {
      min-width: 600px;
      max-width: 600px;
      position: absolute;
      top: 80px;
      max-height: 150px;
      height: auto;
      overflow: auto;
      @media only screen and (max-width: 760px) {
        min-width: 400px;
      }
      @media only screen and (max-width: 425px) {
        min-width: 0;
      }
      .prediction {
        padding: 5px 10px;
        margin: 5px 0;
        border: 1px solid #000;
        cursor: pointer;
      }
    }
  }
}
