// menu
.wrappper_container {
  position: relative;
  display: flex;
  @include respond-to(desktop) {
    flex-direction: column;
  }
  .wrapper_side_right {
    position: relative;
    background: #dfdfdf;
    height: calc(100vh);
    padding: 15px;
    width: 100%;
    @include respond-to(desktop) {
      height: auto;
      padding: 5px;
    }
    .wrapper_side_right_inner {
      position: relative;
      background: #ffff;
      height: calc(100vh - 30px);
      overflow-y: auto;
      @include respond-to(desktop) {
        height: auto;
      }

      // buttons
      .filters {
        position: relative;
        padding: 40px;
        @include respond-to(desktop) {
          padding: 10px;
        }

        button {
          margin: 0;
          padding: 5px 20px;
          background-color: #9abf0f;
          color: #fff;
          border: 0;
          font-size: 18px;
          &.btn-red {
            background-color: red;
          }
          &.btn-green {
            background-color: green;
          }
        }
      }

      .customTable {
        padding: 0 40px;
        position: relative;
        @include respond-to(desktop) {
          padding: 10px;
        }

        .head {
          th {
            background-color: #000;
            color: #fff;
          }
        }
        tr {
          border-bottom: 1px solid #dfdfdf;
          td {
            &:first-child {
              border-left: 1px solid #dfdfdf;
              max-width: 50px;
              overflow: hidden;
            }
            &:last-child {
              border-right: 1px solid #dfdfdf;
            }
            .c_btn {
              width: 20px;
              height: 20px;
              background: #dfdfdf;
              border-radius: 50%;
              position: relative;
              &.btn_orange_clr {
                background: orange;
              }
              &.btn_orange_green_clr {
                background-color: orange;
                &::before {
                  content: "";
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background: green;
                  position: absolute;
                  left: 10px;
                }
              }
              &.btn_green_clr {
                background: green;
              }
            }
          }
          &.even {
            td {
              background-color: #efefef;
            }
          }
        }

        .pagination {
          position: relative;
          display: flex;
          justify-content: space-between;
          button {
            border-right: 0;
            border: 1px solid #9abf0f;
            &:last-child {
              border-right: 1;
            }
          }
        }
      }
    }
  }

  .wrapper_side_left {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #000;
    color: #fff;
    @include respond-to(desktop) {
      height: auto;
    }
    .header {
      position: relative;
      @include respond-to(desktop) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .menuBtn {
        display: none;
        @include respond-to(desktop) {
          display: block;
          margin-right: 20px;
          font-size: 40px;
        }
      }
      .logo_info {
        padding: 20px;
        max-width: 130px;
      }
    }

    .menuList {
      position: relative;
      @include respond-to(desktop) {
        display: none;
        &.active {
          display: block;
        }
      }
      .menuListItem {
        position: relative;
        padding: 10px 20px;
        cursor: pointer;
        &.active {
          background-color: #9abf0f;
        }
      }
    }
    .copyRight {
      position: absolute;
      bottom: 10px;
      width: 100%;
      justify-content: center;
      display: flex;
      @include respond-to(desktop) {
        display: none;
      }
    }
  }
}

// login
.loginContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 360px;
  left: calc(50% - 160px);
  .infoBtn {
    display: flex;
    justify-content: flex-end;
  }
}

// loading

.loadingIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 40px;
}

//
.leadForm {
  padding: 20px;
  .accordion-body,
  .leadFormInner {
    background: #efefef;
  }
  .leadFormInner {
    padding: 20px;
  }
}

//
.QuoteForm {
  padding: 20px;
  .accordion-body,
  .leadFormInner {
    background: #efefef;
  }
  .mapToggle {
    margin-bottom: 70px !important;
  }
  .btns {
    position: absolute !important;
    background: #111111;
    top: -65px;
    height: 65px;
    .btn {
      cursor: pointer !important;
    }
  }

  #customMap,
  .custom-container,
  .location {
    height: calc(100vh - 120px);
    overflow-y: auto;
  }

  .leadFormInner {
    padding: 20px;
  }
}

.custom_legend {
  position: relative;
  display: flex;
  align-items: center;
  @include respond-to(desktop) {
    flex-direction: column;
  }
  .custom_legendItem {
    padding-left: 30px;
    position: relative;
    padding-right: 10px;
    display: flex;
    align-items: center;
    &::before {
      content: " ";
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      left: 0;
    }
    &.yash_clr {
      &::before {
        background-color: #dfdfdf;
      }
    }
    &.orange_clr {
      &::before {
        background-color: orange;
      }
    }
    &.orange_green_clr {
      padding-left: 40px;
      &::before {
        background-color: orange;
      }
      &::after {
        content: " ";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        left: 10px;
        z-index: 1;
        background: green;
      }
    }

    &.green_clr {
      &::before {
        background-color: green;
      }
    }
  }
}

//
.calenderForm {
  position: relative;
  padding: 20px;
  height: calc(100vh - 30px);
  .fc {
    height: 100%;
    table {
      border-color: #dfdfdf;
      td,
      th {
        border-color: #dfdfdf;
        background-color: rgb(44, 62, 80);
        cursor: pointer;

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

//
.postsSlider {
  position: relative;
  height: 400px;
  padding: 10px 0;
  .carousel {
    position: relative;
    height: inherit;
    padding: 20px;
    img {
      width: 100%;
      height: 400px;
      border: 1px solid #198754;
      object-fit: contain;
    }
    button {
      background-color: red !important;
      height: 5px;
    }
    .carousel-indicators {
      margin: 0;
    }
    .carousel-caption {
      position: absolute;
      right: 0;
      padding: 10px;
      color: #fff;
      top: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: fit-content;
      left: 0;
      color: #fff;
      justify-content: flex-start;
      background: rgba(0, 0, 0, 0.6196078431);
      h3 {
        text-transform: capitalize;
        font-size: 30px;
      }
      .stats {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: #fff;
        flex-direction: column;
        div {
          padding: 0 10px;
        }
      }
    }
  }
}
